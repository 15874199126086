<template>
  <div class="setting">
      <el-form label-width="auto" size="small">
        <el-form-item label="当前使用的活动">
          <el-select v-model="submitData.LiveActivityId" filterable>
            <el-option
              v-for="item in activitySelector"
              :key="item.ActivityId"
              :label="item.ActivityName"
              :value="item.ActivityId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <div class="save-btn">
        <el-button type="primary" @click="saveSetting">保存</el-button>
      </div>
  </div>
</template>

<script>
import Raffle from "@/api/raffle.js";

export default {
  data() {
    return {
      activitySelector: [],
      submitData: {
        LiveActivityId: 0,
      },
    };
  },

  mounted() {
    this.initSetting();
  },

  methods: {
    // 初始化设置
    async initSetting() {
      try {
        let { data } = await Raffle.initSetting();
        this.activitySelector = [
          { ActivityName: "不选择", ActivityId: 0 },
          ...data.activitySelector,
        ];
        this.submitData = data.raffleSetting;
      } catch (err) {
        console.log(err);
      }
    },

    // 保存设置
    async saveSetting(){
      try {
        let { errcode } = await Raffle.saveSetting(this.submitData)
        if(errcode == 0){
          this.$message.success("保存成功")
          this.initSetting()
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.setting {
  position: relative;
  .el-form{
    margin-top: 20px;
  }

  .save-btn{
    position: fixed;
    bottom: 0;
    left: 50vw;
    padding: 20px 0;
  }
}
</style>
